<template>
  <div class="fanview-card">
    <!-- Header -->
    <header :style="getHeaderStyle()">
      <div class="top">
        <h1 :style="{ maxWidth: '400px' }">
          <div
            class="d-inline"
            @click="() => copyText(
              identity,
              'ID copiado para área de transferência',
            )"
          >
            <vs-chip
              v-if="checkItemAccess('Admin')"
              class="mr-2 cursor-pointer"
              color="#C7C7CC"
              :style="{ color: '#323232' }"
            >
              {{ identity }}
            </vs-chip>
          </div>

          <span v-if="fanName && fanName !== ''">
            {{ fanName }}
          </span>
          <span
            v-else
            class="text-muted"
          >
            N/I
          </span>
        </h1>

        <div class="top--btn-group">
          <vs-tooltip
            text="Enviar E-Mail"
            delay=".2s"
          >
            <button
              class="btn-reset"
              :disabled="email.length === 0"
              @click="openSendEmailPopup"
            >
              <span
                :style="{ color: colorSecondary }"
                class="mdi mdi-email-outline"
              />
            </button>
          </vs-tooltip>

          <vs-tooltip
            text="Enviar SMS"
            delay=".2s"
          >
            <button
              class="btn-reset"
              :disabled="phone.length === 0"
              @click="openSendSMSPopup"
            >
              <span
                :style="{ color: colorSecondary }"
                class="mdi mdi-message-outline"
              />
            </button>
          </vs-tooltip>

          <vs-tooltip
            text="Enviar WhatsApp"
            delay=".2s"
          >
            <button
              class="btn-reset"
              :disabled="phone.length === 0"
              @click="openSendWhatsPopup"
            >
              <span
                :style="{ color: colorSecondary }"
                class="mdi mdi-whatsapp"
              />
            </button>
          </vs-tooltip>

          <vs-tooltip
            v-if="checkItemAccess('LGPD')"
            text="Ver Contato"
            delay=".2s"
          >
            <button
              class="btn-reset"
              @click="openContactPopup"
            >
              <span
                :style="{ color: '#C7C7CC' }"
                class="mdi mdi-dots-vertical"
              />
            </button>
          </vs-tooltip>
        </div>
      </div>

      <div class="bottom">
        <div>
          <span class="title">
            ID desde
          </span>

          <span
            v-if="fanData.idSince"
            class="contrast-4"
          >
            {{ fanData.idSince }}
          </span>
          <span
            v-else
            class="text-muted"
          >
            N/I
          </span>
        </div>

        <span class="contrast-3">
          |
        </span>

        <div>
          <span class="title">
            Origem do ID
          </span>

          <span
            v-if="fanData.idOrigin"
            class="contrast-4"
          >
            {{ fanData.idOrigin }}
          </span>
          <span
            v-else
            class="text-muted"
          >
            N/I
          </span>
        </div>

        <span
          v-if="fanData.subOwnerDesc && (fanData.subOwnerName || fanData.subOwnerNick)"
          class="contrast-3"
        >
          |
        </span>

        <div v-if="fanData.subOwnerDesc && (fanData.subOwnerName || fanData.subOwnerNick)">
          <span class="title">
            {{ fanData.subOwnerDesc }}
          </span>

          <span class="contrast-4">
            <template v-if="fanData.subOwnerNick">
              {{ fanData.subOwnerNick }}
            </template>
            <template v-else>
              {{ fanData.subOwnerName }}
            </template>
          </span>
        </div>
      </div>
    </header>

    <!-- Main -->
    <main :style="{ height: '100%' }">
      <!-- Fan Data -->
      <div :style="{ flex: highlightsNotEmpty ? '.65' : '1' }">
        <h2 class="contrast-2">
          Dados do Fã
        </h2>

        <div class="fan-data--content">
          <div class="checklist-table">
            <div class="col">
              <CheckItem
                label="Endereço"
                :checked="fanData.address"
              />

              <CheckItem
                label="CPF"
                :checked="fanData.cpf"
              />
            </div>

            <div class="col">
              <CheckItem
                label="Email"
                :checked="fanData.email"
              />

              <CheckItem
                label="Opt In"
                :checked="fanData.optIn"
              />
            </div>

            <div class="col">
              <CheckItem
                label="Telefone"
                :checked="fanData.phone"
              />

              <CheckItem
                :label="fanData.fanIdDesc"
                :checked="fanData.fanId"
              />
            </div>
          </div>

          <div class="info-table">
            <div class="col">
              <InfoItem
                label="Cidade"
                transform="capitalize"
                :value="fanData.city || 'N/I'"
                text-width="110px"
              />

              <InfoItem
                label="Filhos"
                :value="fanData.children ? String(fanData.children) : '0'"
                text-width="110px"
              />
            </div>

            <div class="col">
              <InfoItem
                label="Estado"
                transform="uppercase"
                :value="fanData.state || 'N/I'"
                text-width="110px"
              />

              <InfoItem
                label="Gênero"
                transform="capitalize"
                :value="fanData.gender || 'N/I'"
                text-width="110px"
              />
            </div>

            <div class="col">
              <InfoItem
                label="Estado Cívil"
                transform="capitalize"
                :value="fanData.maritalStatus || 'N/I'"
                text-width="110px"
              />

              <InfoItem
                label="Idade"
                :value="
                  fanData.age
                    ? `${fanData.age}`
                    : 'N/I'
                "
                text-width="110px"
              />
            </div>
          </div>
        </div>
      </div>

      <Divider
        v-if="highlightsNotEmpty"
        direction="vertical"
        :theme="theme"
      />

      <!-- Highlights -->
      <div class="highlights fan-highlights">
        <div
          v-for="(highlight, highlightIdx) in filteredHighlights"
          :key="highlightIdx"
          :style="{ marginBottom: highlight.value ? 'auto' : '0' }"
          class="fan-highlight"
        >
          <h2 class="contrast-2">
            {{ highlight.idxText }}
          </h2>

          <vs-tooltip
            v-if="isIconIndicator(highlight.idxId)"
            :text="highlight.value"
          >
            <div
              v-if="isIconIndicator(highlight.idxId)"
              class="fan-highlight--icon"
            >
              <span
                class="material-icons-outlined"
                :style="{ color: colorPrimary }"
              >
                {{ getSportIcon(highlight.value).icon }}
              </span>
            </div>
          </vs-tooltip>
          <div
            v-else-if="highlight.value && isImageIndicator(highlight.idxId)"
            class="fan-highlight--image"
          >
            <img
              :src="getImagePath(highlight.value, highlight.idxId)"
              :alt="highlight.value[0]"
              @error="getFallbackPath"
            >
          </div>
          <span v-else>
            {{ highlight.value }}
          </span>
        </div>
      </div>
    </main>

    <!-- Contact Popup -->
    <FanNumberMgmt
      :document="document"
      :fan-numbers="fanNumbers"
      :identity="identity"
      :name="fanName"
      :popup-active="contactPopup"
      @close-popup="contactPopup = false"
      @sync="sync"
    />

    <!-- Send Email Popup -->
    <vs-popup
      :active.sync="sendEmailPopup"
      title="Enviar Email"
    >
      <EmailForm
        :email-body="emailBody"
        :email-subject="emailSubject"
        :send-single="true"
        :payload="{ email }"
        :selected-temp-id="templateId"
        :selected-temp-name="templateName"
        :selected-temp-url="templateUrl"
        @close-parent-popup="closeSendEmailPopup"
        @set-email-body="(val) => emailBody = val"
        @set-email-subject="(val) => emailSubject = val"
        @set-selected-temp-id="(val) => templateId = val"
        @set-selected-temp-name="(val) => templateName = val"
        @set-selected-temp-url="(val) => templateUrl = val"
      />
    </vs-popup>

    <!-- Send SMS Popup -->
    <vs-popup
      :active.sync="sendSMSPopup"
      title="Enviar SMS"
    >
      <MessageForm
        notification-type="sms"
        :send-single="true"
        :sms-code="smsCode"
        :sms-text="smsText"
        :submit-disabled="messagesDisabled"
        :payload="{ identity }"
        @close-parent-popup="closeSendSMSPopup"
        @set-sms-code="(val) => smsCode = val"
        @set-sms-text="(val) => smsText = val"
        @set-submit-disabled="(val) => messagesDisabled = val"
      />
    </vs-popup>

    <!-- Send WhatsApp Popup -->
    <vs-popup
      :active.sync="sendWhatsPopup"
      title="Enviar WhatsApp"
    >
      <MessageForm
        notification-type="whatsapp"
        :payload="{ identity }"
        :send-single="true"
        :submit-disabled="messagesDisabled"
        :whats-code="whatsCode"
        :whats-text="whatsText"
        @close-parent-popup="closeSendWhatsPopup"
        @set-submit-disabled="(val) => messagesDisabled = val"
        @set-whats-code="(val) => whatsCode = val"
        @set-whats-text="(val) => whatsText = val"
      />
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import EmailForm from '../Campaign/EmailForm.vue';
import MessageForm from '../Campaign/MessageForm.vue';
import CheckItem from './CheckItem.vue';
import Divider from './Divider.vue';
import FanNumberMgmt from './FanNumberMgmt/index.vue';
import InfoItem from './InfoItem.vue';

/* Helpers */
import getTextContrast from '@/helpers/get-text-contrast';
import getSportIcon from './helpers/get-sport-icon';

/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

import System from '@/services/system';

const systemService = new System();

export default {
  name: 'FanID',
  components: {
    CheckItem,
    Divider,
    EmailForm,
    FanNumberMgmt,
    InfoItem,
    MessageForm,
  },
  props: {
    fanHighlights: {
      type: Array,
      default: () => ([]),
    },
    fanNumbers: {
      type: Array,
      default: () => ([]),
    },
    fanData: {
      type: Object,
      default: () => ({}),
    },
    document: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    fanName: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      default: '',
    },
    identity: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    images: [],
    /* Send Single Email */
    emailBody: '',
    emailSubject: '',
    templateName: '',
    templateUrl: '',
    templateId: 0,
    /* Send Single Message */
    messagesDisabled: true,
    smsCode: null,
    smsText: '',
    whatsCode: null,
    whatsText: '',
    /* Popups */
    contactPopup: false,
    sendEmailPopup: false,
    sendSMSPopup: false,
    sendWhatsPopup: false,
  }),
  computed: {
    /* Style */
    boxShadowDark() {
      return `
        rgba(255, 255, 255, 0.05) 0px -23px 25px 300px inset,
        rgba(255, 255, 255, 0.05) 0px -36px 30px 300px inset,
        rgba(255, 255, 255, 0.05) 0px 23px 25px 300px inset
      `;
    },
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    filteredHighlights() {
      if (this.fanHighlights.length > 0) {
        return this.fanHighlights.filter((h) => {
          if (h.idxId !== -1 && h.value) {
            return h[0];
          }
        });
      } else {
        return [];
      }
    },
    highlightsNotEmpty() {
      return this.filteredHighlights.length > 0;
    },
    textContrast() {
      return this.getTextContrast(this.colorPrimary);
    },
    /* Add to this list all indicators which values must be used to display an icon */
    iconIndicators() {
      return [314];
    },
    /* Add to this list all indicators which values must be used to display an image */
    imageIndicators() {
      return [110];
    },
  },
  created() {
    this.getImages();
  },
  methods: {
    /* API */
    async getImages() {
      this.images = await systemService.getImages();
    },
    getImagePath(fileName, indicator) {
      if (fileName) {
        if (fileName[0]) {
          return systemService.getImagePath(
            this.images,
            fileName[0],
            indicator,
          );
        }
      }
    },
    getFallbackPath(evt) {
      evt.target.src = `${configDomain}/images/api/outro.png`;
    },
    sync() {
      this.$emit('sync');
    },
    /* Style */
    getHeaderStyle() {
      const textColor = this.textContrast === 'light' ? 'white' : 'black';

      return {
        backgroundColor: this.colorPrimary,
        boxShadow: this.boxShadowDark,
        color: textColor,
        gap: '0.5rem',
        minHeight: '6.5rem',
      };
    },
    /* Helpers */
    checkItemAccess(access) {
      if (!access) return true;
      return this.$store.state.loginData.accessValues.includes(access);
    },
    copyText(text, message) {
      navigator.clipboard.writeText(text);

      this.$vs.notify({
        title: 'Copiado',
        text: message || '',
        color: 'primary',
      });
    },
    getTextContrast(hex) {
      return getTextContrast(hex);
    },
    getSportIcon(sportName) {
      return getSportIcon(sportName);
    },
    isIconIndicator(indicator) {
      return this.iconIndicators.includes(Number(indicator));
    },
    isImageIndicator(indicator) {
      return this.imageIndicators.includes(Number(indicator));
    },
    /* Popups */
    closeContactPopup() {
      this.contactPopup = false;
    },
    closeSendEmailPopup() {
      this.sendEmailPopup = false;
    },
    closeSendSMSPopup() {
      this.sendSMSPopup = false;
    },
    closeSendWhatsPopup() {
      this.sendWhatsPopup = false;
    },
    openContactPopup() {
      this.contactPopup = true;
    },
    openSendEmailPopup() {
      this.closeContactPopup();
      this.sendEmailPopup = true;
    },
    openSendSMSPopup() {
      this.closeContactPopup();
      this.sendSMSPopup = true;
    },
    openSendWhatsPopup() {
      this.closeContactPopup();
      this.sendWhatsPopup = true;
    },
  },
};
</script>

<style lang="scss">
.btn-reset {
  appearance: none;
  background: transparent;
  border: 0;
  outline: 0;
  box-shadow: none;
}

main {
  overflow-y: scroll;
}

.fan-data--content {
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: 2.5rem;
  height: calc(100% - 1rem);
  width: 100%;

  .checklist-table, .info-table {
    display: flex;
    flex-flow: row wrap;
    gap: 1.75rem;
    margin-bottom: .5rem;
    width: 100%;

    .col {
      display: flex;
      flex-flow: column nowrap;
      gap: 1rem;
      width: 10px;
    }
  }
}

.fan-highlights {
  display: flex;
  flex-flow: column nowrap;

  .fan-highlight {
    .con-vs-tooltip {
      width: 56px;
    }

    .fan-highlight--icon {
      align-items: center;
      align-self: flex-start;
      border: 1px solid #C4C4C6;
      border-radius: .688rem;
      display: flex;
      height: 3.5rem;
      justify-content: center;
      width: 3.5rem;

      span {
        font-size: 2rem;
      }
    }

    .fan-highlight--image {
      align-items: center;
      display: flex;
      height: 3.5rem;
      justify-content: center;
      width: 3.5rem;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
