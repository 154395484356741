<template>
  <div :class="className">
    <header
      :id="`scrollable-header@${title}`"
      :class="scrollableTabs ? 'tabs-card-header--scrollable' : null"
    >
      <div class="top">
        <h1 class="contrast-1">
          {{ title }}
        </h1>
      </div>

      <!-- Tab Bar -->
      <nav
        v-if="showTabBar"
        :class="theme === 'light' ? 'tab-bar' : 'tab-bar tab-bar--dark'"
      >
        <div
          v-for="tab, index in tabBar"
          :key="`${tab}${index}`"
          :class="tab.title === selected.title ? 'selected-tab' : 'regular-tab'"
        >
          <div
            v-if="tab !== '|'"
            class="tab-bar-item"
            :style="{
              backgroundColor: tab.title === selected.title ? colorSecondary : 'transparent',
              color: tab.title === selected.title ? buttonTextColor : '#94949A',
            }"
            @click="selectTab(tab)"
          >
            <span>{{ tab.title }}</span>
          </div>

          <div
            v-else
            class="tab-bar-divider"
          />
        </div>
      </nav>
    </header>

    <main
      class="tabs"
      :style="scrollableTabs ? { paddingTop: '7.475rem' } : null"
    >
      <template v-if="scrollableTabs">
        <div
          v-for="tab in tabs"
          :key="tab.title"
          class="tabs-content--scrollable"
          :style="{ height: showTabBar ? 'calc(100% - 1.5rem)' : '100%' }"
        >
          <!-- Scroll Link -->
          <div
            :id="tab.title"
            :style="{ position: 'relative', top: '-125px' }"
          />

          <!-- Title -->
          <vs-divider
            :style="{ color: colorSecondary }"
            position="left"
          >
            <h1 :style="{ margin: '0' }">
              {{ tab.title }}
            </h1>
          </vs-divider>

          <main>
            <!-- Tab Brands -->
            <div
              v-if="tab.brands ? tab.brands.filter((b) => b.value).length > 0 : false"
              class="brands"
            >
              <vs-tooltip
                v-for="item in tab.brands.filter((b) => b.value)"
                :key="`${item.idxId}`"
                :text="`${item.idxText}`"
                position="top"
              >
                <div class="brand">
                  <img
                    :src="getImagePath(item.value, item.idxId)"
                    :alt="item.value[0]"
                    @error="getFallbackPath"
                  >
                </div>
              </vs-tooltip>
            </div>

            <Divider
              v-if="tab.brands ? tab.brands.filter((b) => b.value).length > 0 : false"
              direction="vertical"
              :theme="theme"
            />

            <!-- Info Group -->
            <div
              v-if="tab.content ? tab.content.length > 0 : false"
              class="info-group"
            >
              <InfoItem
                v-for="item in tab.content"
                :key="`${item.idxId}${item.idxText}`"
                :fallback-text="fallbackText"
                :label="item.idxText"
                :value="item.value"
              />
            </div>

            <Divider
              v-if="tab.highlights ? tab.highlights.length > 0 : false"
              direction="vertical"
              :theme="theme"
            />

            <!-- Highlights -->
            <div
              v-if="tab.highlights ? tab.highlights.length > 0 : false"
              class="highlights"
            >
              <div class="info-group">
                <InfoItem
                  v-for="item in tab.highlights"
                  :key="`${item.idxId}${item.idxText}`"
                  :fallback-text="fallbackText"
                  :label="item.idxText"
                  :value="item.value"
                />
              </div>
            </div>
          </main>
        </div>
      </template>
      <main
        v-else
        class="tabs-content"
      >
        <!-- Tab Brands -->
        <div
          v-if="brandsNotEmpty"
          class="brands"
        >
          <vs-tooltip
            v-for="item in brands"
            :key="`${item.idxId}`"
            :text="`${item.idxText}`"
            position="top"
          >
            <div class="brand">
              <img
                :src="getImagePath(item.value, item.idxId)"
                :alt="item.value[0]"
                @error="getFallbackPath"
              >
            </div>
          </vs-tooltip>
        </div>

        <Divider
          v-if="brandsNotEmpty"
          direction="vertical"
          :theme="theme"
        />

        <!-- Info Group -->
        <div
          v-if="contentNotEmpty"
          class="info-group"
        >
          <InfoItem
            v-for="item in selected.content"
            :key="`${item.idxId}${item.idxText}`"
            :fallback-text="fallbackText"
            :label="item.idxText"
            :value="item.value"
          />
        </div>

        <Divider
          v-if="highlightsNotEmpty"
          direction="vertical"
          :theme="theme"
        />

        <!-- Highlights -->
        <div
          v-if="highlightsNotEmpty"
          class="highlights"
        >
          <div class="info-group">
            <InfoItem
              v-for="item in selected.highlights"
              :key="`${item.idxId}${item.idxText}`"
              :fallback-text="fallbackText"
              :label="item.idxText"
              :value="item.value"
            />
          </div>
        </div>
      </main>
    </main>
  </div>
</template>

<script>
/* Components */
import Divider from './Divider.vue';
import InfoItem from './InfoItem.vue';

/* Helpers */
import getTextContrast from '@/helpers/get-text-contrast';

/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;

import System from '@/services/system';

const systemService = new System();

export default {
  name: 'TabsCard',
  components: {
    Divider,
    InfoItem,
  },
  props: {
    tabs: {
      type: Array,
      default: () => ([]),
    },
    className: {
      type: String,
      required: true,
    },
    fallbackText: {
      type: String,
      default: 'N/I',
    },
    theme: {
      type: String,
      default: 'light',
    },
    title: {
      type: String,
      required: true,
    },
    scrollableTabs: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    images: [],
    selected: {},
  }),
  computed: {
    buttonTextColor() {
      return this.getTextContrast(this.colorSecondary) === 'light' ? 'white' : 'black';
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    brands() {
      return this.selected.brands.filter((item) => {
        if (item.value) {
          if (
            item.value[0] !== 'Nenhuma' &&
            item.value[0] !== 'Outra' &&
            item.value[0] !== 'Não tenho preferência'
          ) {
            return item;
          }
        }
      });
    },
    brandsNotEmpty() {
      if (this.brands) {
        return this.brands.length > 0;
      } else {
        return false;
      }
    },
    contentNotEmpty() {
      if (this.selected.content) {
        return this.selected.content.length > 0;
      } else {
        return false;
      }
    },
    highlightsNotEmpty() {
      if (this.selected.highlights) {
        return this.selected.highlights.length > 0;
      } else {
        return false;
      }
    },
    tabBar() {
      let tabBar = [];

      this.tabs.forEach((e) => {
        if (e !== this.tabs[this.tabs.length - 1]) {
          tabBar.push(e);
          tabBar.push('|');
        } else {
          tabBar.push(e);
        }
      });

      return tabBar;
    },
    tabsNotEmpty() {
      return this.tabs.length > 0;
    },
    showTabBar() {
      return this.tabs.length > 1;
    }
  },
  created() {
    this.getImages();

    if (this.tabsNotEmpty) this.selected = this.tabs[0];
  },
  mounted() {
    if (this.scrollableTabs) {
      const card = {
        id: this.title,
        header: document.getElementById(`scrollable-header@${this.title}`),
        lastChapter: document.getElementById(`${this.tabs[this.tabs.length - 1].title}`),
      };

      this.$emit('setup-scrollable-tabs', card);

      this.tabs.forEach((tab) => {
        const chapterElement = document.getElementById(tab.title);
        const offsetTop = chapterElement.getBoundingClientRect().top;
        const offsetBottom = chapterElement.nextSibling.getBoundingClientRect().bottom;

        document.addEventListener('scroll', () => {
          if (
            window.scrollY > offsetTop &&
            window.scrollY < offsetBottom
          ) {
            this.selected = tab;
          }
        });
      });
    }
  },
  methods: {
    /* API */
    async getImages() {
      const resp = await systemService.getImages();

      this.images = resp;
    },
    getImagePath(fileName, indicator) {
      if (fileName) {
        if (fileName[0]) {
          return systemService.getImagePath(
            this.images,
            fileName[0],
            indicator,
          );
        }
      }
    },
    getFallbackPath(evt) {
      evt.target.src = `${configDomain}/images/api/outro.png`;
    },
    /* Helpers */
    getTextContrast(hex) {
      return getTextContrast(hex);
    },
    selectTab(tab) {
      if (this.scrollableTabs) {
        const element = document.getElementById(tab.title);
        element.scrollIntoView();
      }

      this.selected = tab;
    },
  },
};
</script>

<style scoped lang="scss">
.tabs-card-header--scrollable {
  background-color: white;
  position: absolute;
  top: 0;
  transition: box-shadow 0.5s;
  z-index: 9999;
}

.tab-bar {
  align-self: flex-start;
  align-items: center;
  background-color: #E5E5EA;
  border-radius: 3rem;
  display: flex;
  justify-content: space-between;
  min-width: 42%;
  max-width: 100%;
  padding: 3px;
  margin-top: 1rem;

  .tab-bar-item {
    border: 0;
    border-radius: 3rem;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 6px 13px;
    text-overflow: ellipsis;
    transition: .15s ease-in;
    white-space: nowrap;

    @media only screen and (max-width: 1312px) {
      font-size: small;
      line-height: 1rem;
    }
  }

  .regular-tab .tab-bar-item:hover {
    background-color: #D1D1D6 !important;
    color: black !important;
  }

  .tab-bar-divider {
    background-color: #D1D1D6;
    display: block;
    height: 22px;
    margin: 0 5px;
    width: 1px;
  }
}

.tab-bar--dark {
  background-color: #d1d1d60e !important;

  .tab-bar-divider {
    background-color: #d1d1d629 !important;
  }
}

.tabs {
  display: flex;
  flex-flow: column nowrap;
  gap: 2.5rem;
  height: auto;

  .tabs-content {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    height: auto;
    overflow-y: auto;
    padding: 0;
    width: 100%;

    .brands {
      display: flex;
      flex-flow: row wrap;
      gap: .688rem;
      height: 100px;
      width: 100%;

      .brand {
        align-items: center;
        border: 1px solid #C4C4C6;
        border-radius: .688rem;
        display: flex;
        justify-content: center;
        height: 4.56rem;
        width: 4.56rem;

        img {
          object-fit: contain;
          width: 70%;
        }
      }
    }

    .info-group {
      display: flex;
      flex-flow: row wrap;
      gap: 2rem 6rem;
      height: 100%;
      width: 100%;
    }

    .highlights {
      flex: .35;
      height: 100%;

      .info-group {
        align-items: flex-end;
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem 0;
        height: 100%;
        overflow: hidden auto;
        width: 100%;

        @media only screen and (max-width: 1475px) {
          flex-flow: column nowrap;
        }

        .info-item span {
          font-size: 1.5em;
        }
      }
    }
  }

  .tabs-content--scrollable {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.15rem;

    h1 {
      margin-bottom: 1rem;
    }

    main {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      height: 100%;
      min-height: 200px;
      overflow-y: auto;
      padding: 0;
      width: 100%;

      .brands {
        display: flex;
        flex-flow: row wrap;
        gap: .688rem;
        height: 100px;
        width: 100%;

        .brand {
          align-items: center;
          border: 1px solid #C4C4C6;
          border-radius: .688rem;
          display: flex;
          justify-content: center;
          height: 4.56rem;
          width: 4.56rem;

          img {
            object-fit: contain;
            width: 70%;
          }
        }
      }

      .info-group {
        display: flex;
        flex-flow: row wrap;
        gap: 2rem 6rem;
        height: 100%;
        width: 100%;
      }

      .highlights {
        flex: .35;
        height: 100%;

        .info-group {
          align-items: flex-end;
          display: flex;
          flex-flow: column nowrap;
          gap: 1rem 0;
          height: 100%;
          overflow: hidden auto;
          width: 100%;

          @media only screen and (max-width: 1475px) {
            flex-flow: column nowrap;
          }

          .info-item span {
            font-size: 1.5em;
          }
        }
      }
    }
  }
}
</style>
