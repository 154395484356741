<template>
  <TabsCard
    v-if="tabsNotEmpty"
    :class-name="className"
    :fallback-text="fallbackText"
    :tabs="tabs"
    :theme="theme"
    :title="title"
    :scrollable-tabs="scrollableTabs"
    @setup-scrollable-tabs="setupScrollableTabs"
  />
  <div
    v-else
    :class="className"
  >
    <!-- Header -->
    <header :style="{ height: spotlight ? '6.5rem' : '4rem' }">
      <div class="top">
        <h1 class="contrast-1">
          {{ title }}
        </h1>

        <!-- Spotlight -->
        <div
          v-if="spotlight && highlightsNotEmpty"
          :style="{ backgroundColor: colorSecondary }"
          class="card-spotlight"
        >
          <h2 :style="{ color: buttonTextColor }">
            {{ data.highlights[0].idxText }}
          </h2>

          <span
            class="spotlight-value"
            :style="{ color: buttonTextColor }"
          >
            {{
              data.highlights[0].value && data.highlights[0].value[0] !== 'SIM'
                ? data.highlights[0].value[0]
                : fallbackText
            }}
          </span>
        </div>

        <!-- Details -->
        <button
          v-if="hasDetails"
          class="details-link"
          :style="{ color: colorSecondary }"
          @click="goToDetails(
            data.details[0].title,
            data.details[0].type,
          )"
        >
          <span>
            Ver Detalhes
          </span>

          <i class="material-icons">
            chevron_right
          </i>
        </button>
      </div>
    </header>

    <!-- Main -->
    <main
      :style="{
        padding: spotlight ? '1.25rem' : '.625rem 1.25rem 1.25rem',
      }"
    >
      <!-- Content -->
      <div
        v-if="contentNotEmpty"
        class="content"
        :style="{ flex: highlightsNotEmpty ? '.65' : '1' }"
      >
        <div
          v-if="contentType === 'info'"
          class="info-group"
          :style="{ height: theme === 'dark' ? 'calc(425px - 6.5rem)' : '100%' }"
        >
          <InfoItem
            v-for="item in data.content"
            :key="item.idxId + item.idxText"
            :fallback-text="fallbackText"
            :label="item.idxText"
            :value="item.value"
          />
        </div>
        <div
          v-else
          class="checklist"
        >
          <CheckItem
            v-for="item in data.content"
            :key="item.idxId + item.idxText"
            :checked="item.value"
            :label="item.idxText"
          />
        </div>
      </div>

      <Divider
        v-if="highlightsNotEmpty"
        direction="vertical"
        :theme="theme"
      />

      <!-- Highlights -->
      <div
        v-if="highlightsNotEmpty"
        class="highlights"
      >
        <!-- Skip first highlight iteration -->
        <div
          v-if="spotlight"
          class="info-group"
        >
          <InfoItem
            v-for="item in data.highlights.slice(1)"
            :key="item.idxId + item.idxText"
            :fallback-text="fallbackText"
            :label="item.idxText"
            :value="item.value"
          />
        </div>
        <div
          v-else
          class="info-group"
        >
          <InfoItem
            v-for="item in data.highlights"
            :key="item.idxId + item.idxText"
            :fallback-text="fallbackText"
            :label="item.idxText"
            :value="item.value"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/* Components */
import CheckItem from './CheckItem.vue';
import Divider from './Divider.vue';
import InfoItem from './InfoItem.vue';
import TabsCard from './TabsCard.vue';

/* Helpers */
import getTextContrast from '@/helpers/get-text-contrast';

export default {
  name: 'FanviewCard',
  components: {
    CheckItem,
    Divider,
    InfoItem,
    TabsCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    /* Indicates if the "content" section is made up of check items or info items */
    contentType: {
      type: String,
      default: 'info',
    },
    fallbackText: {
      type: String,
      default: 'N/I',
    },
    theme: {
      type: String,
      default: 'light',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    tabs: {
      type: Array,
      default: () => ([]),
    },
    fanId: {
      type: Number,
      required: true,
    },
    fanName: {
      type: String,
      required: true,
    },
    document: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    spotlight: {
      type: Boolean,
      default: false,
    },
    scrollableTabs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonTextColor() {
      return this.getTextContrast(this.colorSecondary) === 'light' ? 'white' : '#1C1C1E';
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    className() {
      let className = 'fanview-card';

      if (this.theme !== 'light') {
        className += ' fanview-card--dark';
      }

      if (this.title === 'Marcas e Consumo') {
        // Add CSS target to fix the card's tabs overflow
        className += ' fanview-card--last';
      }

      if (this.scrollableTabs) {
        className += ' tabs-card--scrollable';
      }

      return className;
    },
    contentNotEmpty() {
      if (this.data.content) {
        return this.data.content.length > 0;
      } else {
        return false;
      }
    },
    highlightsNotEmpty() {
      if (this.data.highlights) {
        return this.data.highlights.length > 0;
      } else {
        return false;
      }
    },
    tabsNotEmpty() {
      return this.tabs.length > 0;
    },
    hasDetails() {
      return this.data.details && this.data.details.length > 0;
    },
  },
  methods: {
    /* Helpers */
    getTextContrast(hex) {
      return getTextContrast(hex);
    },
    setupScrollableTabs(card) {
      this.$emit('setup-scrollable-tabs', card);
    },
    /* Router */
    goToDetails(title, type) {
      this.$router.push({
        name: 'Fanview Details',
        params: {
          fanId: this.fanId,
          fanName: this.fanName,
          document: this.document,
          email: this.email,
          phone: this.phone,
          title,
          type: String(type).toLowerCase(),
        },
      });
    },
  }
}
</script>

<style lang="scss">
.fanview-card {
  background-color: white;
  border-radius: .4rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  height: auto;
  overflow: hidden;
  position: relative;
  transition: all .3s ease;
  width: calc(50% - .625rem);

  // Contrast Colors
  .contrast-1 {
    color: black;
  }

  .contrast-2 {
    color: #48484A;
  }

  .contrast-3 {
    color: #8E8E93;
  }

  .contrast-4 {
    color: #C7C7CC;
  }

  // Scrollbar
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 20px;
    border: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
  }

  // Responsive Design
  @media only screen and (max-width: 1280px) {
    width: 100%;
    transition: all .1s ease;
  }

  @media only screen and (max-width: 528px) {
    height: auto;
    min-height: 425px;
  }

  // Headings
  h1 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
  }

  h2 {
    font-size: .8rem;
    font-weight: 700;
    margin-bottom: .75rem;
    text-transform: uppercase;
  }

  // Header
  header {
    border-radius: .25rem .25rem .1rem .1rem;
    display: flex;
    flex-flow: column nowrap;
    padding: 1.3rem;
    width: 100%;

    .top {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .details-link {
        align-items: center;
        background: none;
        border: 0;
        border-radius: .23rem;
        display: flex;
        font-weight: bold;
        outline: 0;
        transition: all 0.16s ease-in-out;

        i {
          font-size: 1.35em;
        }

        i,
        span {
          filter: brightness(2);
        }

        &:hover i,
        &:hover span {
          filter: brightness(2.9) saturate(0.7);
          transition: all 0.15s ease-in;
        }

        &:active i,
        &:active span {
          color: white;
        }
      }

      .top--btn-group {
        display: flex;
        gap: .5rem;

        button {
          align-items: center;
          border: 0;
          border-radius: 9999px;
          cursor: pointer;
          display: flex;
          filter: brightness(1.28);
          height: 38px;
          justify-content: center;
          position: relative;
          width: 38px;

          span {
            font-size: 1.35rem;
          }
        }

        button:hover {
          background-color: #cccccc10;
        }

        button:disabled {
          cursor: default;

          span {
            filter: saturate(0.7) brightness(0.7);
          }
        }
      }
    }

    .bottom {
      display: flex;
      gap: .5rem;

      .title {
        font-weight: 600;
      }
    }

    .card-spotlight {
      border-radius: 0 .25rem 0 .5rem;
      display: flex;
      flex-flow: column nowrap;
      height: 6.5rem;
      justify-content: space-between;
      padding: 1.25rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 45%;

      h2 {
        margin-bottom: 0;
      }

      .spotlight-value {
        font-size: 2.15em;
        font-weight: 800;
      }
    }
  }

  // Main
  main {
    display: flex;
    gap: 1rem;
    min-height: calc(425px - 4rem);
    height: 100%;
    padding: 1.25rem;
    width: 100%;

    .content {
      flex: .65;
      height: 100%;

      .checklist {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        width: 100%;
      }

      .info-group {
        display: flex;
        flex-flow: column wrap;
        gap: 2rem .25rem;
        height: 100%;
        overflow: hidden auto;
        width: 100%;

        @media only screen and (max-width: 1475px) {
          flex-flow: column nowrap;
        }
      }
    }

    .highlights {
      flex: .35;
      height: 100%;

      .info-group {
        align-items: flex-end;
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem 0;
        height: 100%;
        overflow: hidden auto;
        width: 100%;

        @media only screen and (max-width: 1475px) {
          flex-flow: column nowrap;
        }
      }
    }
  }
}

// Dark Theme
.fanview-card--dark {
  background-color: transparent;
  box-shadow:
    rgba(255, 255, 255, 0.05) 0px -23px 25px 300px inset,
    rgba(255, 255, 255, 0.05) 0px -36px 30px 300px inset,
    rgba(255, 255, 255, 0.05) 0px 23px 25px 300px inset;

  // Contrast Colors
  .contrast-1 {
    color: white;
  }

  .contrast-2 {
    color: #b6b6b6;
  }

  .contrast-3 {
    color: #9b9b9be6;
  }

  .contrast-4 {
    color: #2C2C2E;
  }

  // Scrollbar
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(155, 155, 155, 0.6);
  }
}

// Fix card's tabs overflow
.fanview-card--last {
  main {
    overflow-x: hidden;
  }

  .tab-bar {
    .tab-bar-item > span {
      font-size: .97em;
    }

    .tab-bar-divider {
      margin: 0;
    }

    .tab-bar-item:not(:hover),
    .tab-bar-item:not(:hover) > span,
    .regular-tab:hover ~ .selected-tab .tab-bar-item,
    .regular-tab:hover ~ .selected-tab .tab-bar-item:hover > span {
      max-width: 8vw;

      @media (max-width: 1480px) {
        max-width: 7.5vw;
      }

      @media (max-width: 1424px) {
        max-width: 7.25vw;
      }

      @media (max-width: 1368px) {
        max-width: 7.15vw;
      }

      @media (max-width: 1312px) {
        max-width: 7vw;
      }

      @media (max-width: 1281px) {
        max-width: 12vw;
      }
    }

    .tab-bar-item:not(:hover) > span,
    .regular-tab:hover ~ .selected-tab .tab-bar-item:hover > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .selected-tab .tab-bar-item,
    .selected-bar .tab-bar-item > span {
      max-width: 100%;
      overflow: visible;
      text-overflow: unset;
    }
  }
}
</style>
