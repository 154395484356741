<template>
  <div>
    <!-- Number List -->
    <div
      v-if="initialValue && initialValue.length > 0"
      class="input-wrapper"
    >
      <button
        v-if="!preferred"
        class="btn-reset"
        @click.prevent="updatePreferredNumber"
      >
        <span class="mdi mdi-star-outline preferred-icon" />
      </button>
      <button
        v-else
        class="btn-reset"
        disabled
        @click.prevent
      >
        <span class="mdi mdi-star preferred-icon--active" />
      </button>

      <vs-input
        v-model="updatedValue"
        class="c-vs-input"
        :readonly="readonly"
        icon-after
        :icon="readonly ? 'remove_red_eye' : 'mode_edit'"
      />

      <vs-tooltip
        text="Copiar"
        position="top"
      >
        <vs-button
          color="dark"
          class="ml-1"
          icon="content_copy"
          type="flat"
          @click="() => copyText(value, `${numberName} copiado para a área de transferência.`)"
        />
      </vs-tooltip>

      <vs-tooltip
        text="Restaurar"
        position="top"
      >
        <vs-button
          v-if="!readonly"
          color="dark"
          icon="refresh"
          type="flat"
          @click="updatedValue = initialValue"
        />
      </vs-tooltip>

      <div class="actions">
        <vs-button
          v-if="!readonly"
          :disabled="!validEdit"
          @click="updateFanNumber"
        >
          Salvar
        </vs-button>

        <vs-button
          v-if="!readonly"
          color="danger"
          icon="delete"
          @click="deleteFanNumber"
        />
      </div>
    </div>

    <!-- Add Button -->
    <vs-button
      v-else-if="!adding"
      class="align-self-start"
      icon="add"
      type="flat"
      @click="handleAdd"
    >
      Adicionar
    </vs-button>

    <!-- Add Form -->
    <div
      v-else
      class="add-wrapper"
    >
      <vs-input
        :id="`fan-number__add-${numberType}`"
        v-model="updatedValue"
        class="c-vs-input"
        icon-after
        :icon="mode_edit"
      />

      <vs-button
        class="ml-1"
        :disabled="!validEdit"
        @click="addFanNumber"
      >
        Adicionar
      </vs-button>

      <vs-button
        color="danger"
        icon="close"
        type="flat"
        radius
        @click="adding = false"
      />
    </div>
  </div>
</template>

<script>
/* Services */
import Fan from '@/services/fan';

const fanService = new Fan();

export default {
  name: 'FanNumber',
  props: {
    numberName: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    numberType: {
      type: Number,
      default: 0,
    },
    identity: {
      type: Number,
      required: true,
    },
    preferred: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    initialValue: '',
    updatedValue: '',
    adding: false,
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    /* Form */
    validEdit() {
      return (this.initialValue !== this.updatedValue);
    },
  },
  watch: {
    value() {
      this.initialValue = this.value;
      this.updatedValue = this.value;
    },
  },
  mounted() {
    this.initialValue = this.value;
    this.updatedValue = this.value;
  },
  methods: {
    /* API */
    addFanNumber() {
      this.$store.dispatch('set_isLoadingActive', true);

      const payload = {
        identity: this.identity,
        ownerId: this.ownerId,
        numberType: this.numberType,
        oldNumber: '',
        newNumber: this.updatedValue,
      };

      fanService.updateFanNumber(payload, this.token)
        .then(() => {
          this.sync();
          this.successAlert();
        })
        .catch((err) => this.errorAlert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.adding = false;
        });
    },
    deleteFanNumber() {
      this.$store.dispatch('set_isLoadingActive', true);

      fanService.deleteFanNumber(
        this.ownerId,
        this.identity,
        this.numberType,
        this.value,
        this.token,
      )
        .then(() => {
          this.sync();
          this.successAlert();
        })
        .catch((err) => this.errorAlert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    updateFanNumber() {
      this.$store.dispatch('set_isLoadingActive', true);

      const payload = {
        identity: this.identity,
        ownerId: this.ownerId,
        numberType: this.numberType,
        oldNumber: this.initialValue,
        newNumber: this.updatedValue,
      };

      fanService.updateFanNumber(payload, this.token)
        .then(() => {
          this.sync();
          this.successAlert();
        })
        .catch((err) => this.errorAlert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    updatePreferredNumber() {
      this.$emit(
        'update-preferred-number',
        this.numberType,
        this.value,
      );
    },
    sync() {
      this.$emit('sync');
    },
    /* Helpers */
    copyText(text, message) {
      navigator.clipboard.writeText(text);

      this.$vs.notify({
        title: 'Copiado',
        text: message || '',
        color: 'primary',
      });
    },
    handleAdd() {
      this.adding = true;

      const addInputObserver = new MutationObserver((_mutations, obs) => {
        const addInput = document
          .getElementById(`fan-number__add-${this.numberType}`);

        if (addInput) {
          addInput.focus();
          obs.disconnect();
          return;
        }
      });

      addInputObserver.observe(document, {
        childList: true,
        subtree: true
      });
    },
    /* Alerts */
    errorAlert(err) {
      console.error(err);

      this.$vs.notify({
        color: 'danger',
        title: 'Erro',
        text: err,
      });
    },
    successAlert() {
      this.$vs.notify({
        color: 'success',
        title: 'Sucesso',
        text: `${this.numberName} salvo com sucesso!`,
      });
    },
  },
};
</script>

<style lang="scss">
.input-wrapper {
  align-items: center;
  display: flex;

  span.icon--copy {
    font-size: 0.95em !important;
    margin-left: 0.15rem;
    cursor: pointer;
  }

  .actions {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-left: auto;
  }
}

.add-wrapper {
  align-items: center;
  display: flex;
}

.input-wrapper,
.add-wrapper {
  .c-vs-input {
    font-size: 1.15em;
    width: 250px;
  }
}

.btn-reset {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 99px;
  box-shadow: none;
  cursor: pointer;
  height: 28px;
  margin-right: 3px;
  outline: 0;
  padding: 5px;
  width: 28px;
}

.btn-reset:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.preferred-icon {
  color: #444;
  font-size: 1.17em;
}

.preferred-icon--active {
  color: gold;
  font-size: 1.17em;
}
</style>
