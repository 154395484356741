<template>
  <div class="check-item">
    <div
      v-if="checked"
      class="icon"
    >
      <img
        v-if="theme === 'light'"
        :src="require('./assets/check-circle.svg')"
        alt="OK"
      >
      <img
        v-else
        :src="require('./assets/check-circle-dark.svg')"
        alt="OK"
      >
    </div>
    <div
      v-else
      class="icon"
    >
      <img
        :src="require('./assets/cancel-outline.svg')"
        alt="Not available"
      >
    </div>

    <span class="text contrast-1">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CheckItem',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
};
</script>

<style scoped lang="scss">
.check-item {
  display: flex;
  flex-flow: row nowrap;
  gap: .25rem;

  .icon {
    display: block;
    height: 1.15rem;
    width: 1.15rem;

    img {
      max-height: 100%;
    }
  }

  .text {
    font-size: .9rem;
    font-weight: 700;
  }
}
</style>
