<template>
  <vs-popup
    id="contact-popup"
    :active.sync="popupActive"
    title="Dados de Contato"
    @close="closePopup"
  >
    <!-- Close -->
    <vs-button
      class="contact-popup--close"
      color="white"
      icon="close"
      type="relief"
      @click="closePopup"
    />

    <!-- Header -->
    <header
      class="contact-popup--top"
      :style="{ backgroundColor: colorPrimaryLight }"
    >
      <h1 class="mb-1">
        {{ name }}
      </h1>
    </header>

    <!-- Content -->
    <div class="contact-popup">
      <form>
        <section
          v-for="item in contactItems"
          :key="item.label"
          class="contact-item"
        >
          <span
            class="material-icons icon"
            :style="{ color: colorSecondary }"
          >
            {{ item.icon }}
          </span>

          <div class="contact-item--content">
            <h4>{{ item.label }}</h4>

            <!-- Numbers -->
            <div
              class="d-flex flex-column"
              :style="{ gap: '0.45rem' }"
            >
              <FanNumber
                v-for="(number, numberIdx) in item.numbers"
                :key="numberIdx"
                :identity="identity"
                :number-name="item.label"
                :number-type="item.numberType"
                :preferred="number.preferred"
                :readonly="number.readonly"
                :value="number.value"
                @sync="sync"
                @update-preferred-number="updatePreferredNumber"
              />
            </div>
          </div>
        </section>
      </form>
    </div>
  </vs-popup>
</template>

<script>
/* Components */
import FanNumber from './FanNumber.vue';

/* Services */
import Fan from '@/services/fan';

const fanService = new Fan();

export default {
  name: 'FanNumberMgmt',
  components: { FanNumber },
  props: {
    fanNumbers: {
      type: Array,
      required: true,
    },
    document: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    identity: {
      type: Number,
      required: true,
    },
    popupActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    /* Theme */
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorPrimaryLight() {
      let hex = this.colorPrimary, amount = 30;

      // Remove the "#" symbol from the HEX color
      hex = hex.replace('#', '');

      // Convert the HEX color to RGB
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      // Adjust the RGB values to lighten the color
      r = Math.min(255, r + amount);
      g = Math.min(255, g + amount);
      b = Math.min(255, b + amount);

      // Convert the RGB values back to HEX
      let newHex = '#' + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');

      return newHex;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    /* Form */
    contactItems() {
      const emailAddresses = this.fanNumbers
        .filter((n) => n.numberType === 4)
        .map((n) => ({ value: n.number, preferred: (typeof n.prefered !== 'undefined' && n.prefered !== null) ? n.prefered : false }));

      const phoneNumbers = this.fanNumbers
        .filter((n) => n.numberType === 3)
        .map((n) => ({ value: n.number, preferred: (typeof n.prefered !== 'undefined' && n.prefered !== null) ? n.prefered : false}));

      // Make "Add" button available
      emailAddresses.push({ value: '' });
      phoneNumbers.push({ value: '' });

      const contactItems = [
        {
          label: 'Email',
          icon: 'email',
          numberType: 4,
          numbers: emailAddresses,
        },
        {
          label: 'Telefone',
          icon: 'phone',
          numberType: 3,
          numbers: phoneNumbers,
        },
        {
          label: 'CPF',
          icon: 'person',
          numbers: [
            {
              value: this.getMaskedCPF(this.document),
              readonly: true,
            },
          ],
        },
      ];

      return contactItems;
    },
  },
  methods: {
    /* API */
    sync() {
      this.$emit('sync');
    },
    updatePreferredNumber(numberType, newNumber) {
      this.$store.dispatch('set_isLoadingActive', true);


      const oldNumber = this.fanNumbers.find((n) => {
        return (
          n.numberType === numberType
          && n.prefered
        );
      });

      const payload = {
        ownerId: this.ownerId,
        identity: this.identity,
        numberType,
        oldNumber: oldNumber ? oldNumber.number : '',
        newNumber,
      };

      fanService.updatePreferredNumber(payload, this.token)
        .then(() => this.sync())
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    getMaskedCPF(cpf) {
      const isMasked = (cpf.includes('.') && cpf.includes('-'));

      if (isMasked) return cpf;

      const mask = '###.###.###-##';

      // Remove any non-digit characters from the original string
      const cleanString = cpf.replace(/\D/g, '');

      // Apply the mask to the clean string
      let maskedString = '';
      let j = 0;

      for (let i = 0; i < mask.length; i++) {
        if (mask[i] === '#') {
          maskedString += cleanString[j];
          j++;
        } else {
          maskedString += mask[i];
        }
      }

      return maskedString;
    },
    /* Popups */
    closePopup() {
      this.$emit('close-popup');
    },
  },
};
</script>

<style lang="scss">
#contact-popup .vs-popup {
  position: relative;

  .contact-popup--close {
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
    padding: 8px;
    position: absolute;
    top: -15px;
    right: -15px;

    i {
      color: rgba(0,0,0,.6);
      font-size: 24px;
    }
  }
}

#contact-popup .vs-popup .vs-popup--header {
  display: none !important;
}

#contact-popup .vs-popup .vs-popup--content {
  border-radius: 6px;
  margin: 0;
  padding: 0;
  width: 100%;

  .contact-popup--top {
    align-items: center;
    border-radius: 6px 6px 0 0;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 1.5rem 1.75rem 1.55rem;

    h1 {
      color: white;
      font-size: 1.25rem;
    }
  }

  .contact-popup {
    height: 100%;
    padding: 1.25rem;

    form {
      display: flex;
      flex-flow: column nowrap;
    }
  }
}

.contact-item {
  display: flex;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  gap: 1rem;

  span.icon {
    font-size: 1.65em;
  }

  .contact-item--content {
    display: flex;
    flex-flow: column;
    width: 100%;

    h4 {
      font-size: 1.15em;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
  }
}
</style>
