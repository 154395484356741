<template>
  <div class="info-item">
    <label
      :for="label"
      :style="{ textTransform: 'uppercase' }"
      class="contrast-2"
    >
      {{ label }}
    </label>

    <span
      v-if="!value"
      :id="label"
      class="contrast-3"
    >
      {{ fallbackText }}
    </span>
    <span
      v-else-if="(typeof value === 'string')"
      :id="label"
      class="contrast-1"
      :style="{ maxWidth: textWidth }"
    >
      {{ value }}
    </span>
    <div
      v-else-if="(typeof value === 'object')"
      class="info-items-container"
    >
      <span
        v-for="item in value"
        :id="label"
        :key="item"
        class="contrast-1"
        :style="{ maxWidth: textWidth }"
      >
        {{ item }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoItem',
  props: {
    fallbackText: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true
    },
    textWidth: {
      type: String,
      default: 'auto',
    },
    value: {
      type: [String, Array, Object],
      default: () => ([]),
    },
  },
};
</script>

<style scoped lang="scss">
.info-item {
  display: flex;
  flex-flow: column nowrap;
  width: 170px;

  label {
    font-size: 82%;
    font-weight: 700;
    margin-bottom: 0;
    max-width: 87%;
  }

  span {
    font-size: 1.22em;
    font-weight: 800;
    margin-bottom: 0;
    margin-top: .15rem;
    line-height: 1.5rem;
  }

  .info-items-container {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.25rem;
  }
}
</style>
