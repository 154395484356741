<template>
  <div>
    <!-- Actions -->
    <header class="fanview-header">
      <vs-button
        type="flat"
        text-color="white"
        @click="() => goToContacts(fromFilter)"
      >
        <i class="fa fa-undo-alt mr-1" />

        Voltar para lista
      </vs-button>

      <div class="d-flex align-items-center justify-content-center">
        <vs-button
          v-if="resetPwdLink && resetPwdLink.length > 0"
          color="gray"
          class="mr-2"
          type="border"
          @click="resetPwdPopup = true"
        >
          <i class="mdi mdi-key-link mr-1" />

          Redefinir Senha
        </vs-button>

        <vs-button
          v-if="checkItemAccess('LGPD')"
          color="danger"
          type="border"
          @click="confirmDeleteDialog"
        >
          <i :class="`mdi mdi-delete${deleteDialog ? '' : '-outline'}`" />

          Excluir Cadastro
        </vs-button>
      </div>
    </header>

    <!-- Main -->
    <main
      v-if="Object.keys(fanview).length > 0"
      class="main"
    >
      <!-- Fan ID -->
      <FanID
        v-if="Object.keys(fanview).length !== 0"
        :document="document"
        :email="email"
        :fan-data="fanview.fanData"
        :fan-highlights="fanview.fanHighlights"
        :fan-name="fanName"
        :fan-numbers="fanNumbers"
        :identity="fanId"
        :phone="phone"
        @sync="getFanview"
      />

      <!-- Engagement -->
      <FanviewCard
        v-if="fanview.engagement"
        :data="parsedEngagement"
        :fan-id="fanId"
        :fan-name="fanName"
        :document="document"
        :email="email"
        :phone="phone"
        title="Engajamento"
        content-type="check"
        spotlight
      />

      <!-- Cards -->
      <!-- Tickets & Events -->
      <FanviewCard
        v-if="fanview.cards[0]"
        :data="fanview.cards[0]"
        :fan-id="fanId"
        :fan-name="fanName"
        :theme="fanview.cards[0].theme"
        :title="fanview.cards[0].title"
        :document="document"
        :email="email"
        :phone="phone"
      />

      <!-- Associate Fan -->
      <FanviewCard
        v-if="fanview.cards[1]"
        :data="fanview.cards[1]"
        :fan-id="fanId"
        :fan-name="fanName"
        :theme="fanview.cards[1].theme"
        :title="fanview.cards[1].title"
        :document="document"
        :email="email"
        :phone="phone"
      />

      <!-- Shopping & E-Commerce -->
      <template v-if="fanview.cards[2]">
        <FanviewCard
          v-if="fanview.cards[2].title === 'Lojas e E-Commerce'"
          :fan-id="fanId"
          :fan-name="fanName"
          :tabs="parsedCommerce"
          :theme="fanview.cards[2].theme"
          :title="fanview.cards[2].title"
          :document="document"
          :email="email"
          :phone="phone"
        />
        <FanviewCard
          v-else
          :data="fanview.cards[2]"
          :fan-id="fanId"
          :fan-name="fanName"
          :theme="fanview.cards[2].theme"
          :title="fanview.cards[2].title"
          :document="document"
          :email="email"
          :phone="phone"
        />
      </template>

      <!-- OTT -->
      <FanviewCard
        v-if="fanview.cards[3]"
        :data="fanview.cards[3]"
        :fan-id="fanId"
        :fan-name="fanName"
        :theme="fanview.cards[3].theme"
        :title="fanview.cards[3].title"
        :document="document"
        :email="email"
        :phone="phone"
      />

      <!-- Consumer Cards -->
      <FanviewCard
        v-for="card in fanview.consumerCards"
        :key="card.title"
        fallback-text="N/I"
        :fan-id="fanId"
        :fan-name="fanName"
        :tabs="card.tabs"
        :title="card.title"
        :document="document"
        :email="email"
        :phone="phone"
        :scrollable-tabs="card.scrollableTabs"
        @setup-scrollable-tabs="setupScrollableTabs"
      />
    </main>

    <!-- Popups -->
    <vs-popup
      title="Redefinir Senha"
      :active.sync="resetPwdPopup"
    >
      <div class="d-flex flex-column align-items-center p-2">
        <div class="pt-3 pr-4 pl-4 pb-4 w-75">
          <div class="d-flex flex-row align-items-center">
            <strong
              class="h6 mr-2"
              :style="{ whiteSpace: 'nowrap' }"
            >
              Copiar Link:
            </strong>

            <vs-input
              v-model="resetPwdLink"
              icon="content_copy"
              class="c-vs-input--copy"
              size="large"
              @click="() => copyText(resetPwdLink, `Link copiado para a área de transferência.`)"
            />
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import FanID from './FanID.vue';
import FanviewCard from './FanviewCard.vue';

/* Services */
import Fan from '@/services/fan';

const fanService = new Fan();

export default {
  name: 'Fanview',
  components: {
    FanID,
    FanviewCard,
  },
  props: {
    fanId: {
      type: Number,
      required: true,
    },
    fanName: {
      type: String,
      required: true,
    },
    document: {
      type: String,
      default: '',
    },
    returnLink: {
      type: String,
      default: '',
    },
    fromFilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    /* Fan */
    fanview: {},
    fanNumbers: [],
    email: '',
    phone: '',
    resetPwdLink: '',
    /* Popups */
    deleteDialog: false,
    resetPwdPopup: false,
    /* Other */
    animations: [],
  }),
  computed: {
    accessValues() {
      return this.$store.state.loginData.accessValues;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    sysId() {
      return this.$store.state.sysID;
    },
    /* Additional logic to display cards properly */
    parsedCommerce() {
      let parsed = { ...this.fanview.cards[2] };

      return [
        {
          title: 'Loja Oficial',
          brands: [],
          content: [ ...parsed.content.slice(0, 2) ],
          highlights: [ ...parsed.highlights.slice(0, 3) ],
        },
        {
          title: 'E-Commerce',
          brands: [],
          content: [ ...parsed.content.slice(2, 4) ],
          highlights: [ ...parsed.highlights.slice(3, 6) ],
        },
      ];
    },
    parsedEngagement() {
      let parsed = { ...this.fanview.engagement };

      parsed.content = parsed.content.map((item) => {
        // Case #1: Active Fan
        if (item.idxId === -404) {
          return {
            ...item,
            value: item.value ? item.value[0] === 'Ativo' : false,
          };
        }

        // Case #2: Answered Survey
        if (item.idxId === -27) {
          return {
            ...item,
            value: item.value ? item.value[0].length > 0 : false,
          };
        }

        // Case #3: Shopped Store
        if (item.idxId === -1050) {
          return {
            ...item,
            value: item.value ? item.value[0] === 'Já Comprou' : false,
          };
        }

        // Case #4: Shopped Online
        if (item.idxId === -1051) {
          return {
            ...item,
            value: item.value ? item.value[0] === 'Já Comprou' : false,
          };
        }

        return {
          ...item,
          value: item.value ?
            String(item.value[0]).toLowerCase() === 'sim'
            : false,
        };
      });

      return parsed;
    },
  },
  created() {
    this.getFanview();
    this.getPwdResetLink();
  },
  methods: {
    /* API */
    getFanview() {
      this.$store.dispatch('set_isLoadingActive', true);

      fanService.getFanData(
        this.fanId,
        this.ownerId,
        this.sysId,
      )
        .then((resp) => {
          const email = resp.fanNumbers.find((number) => number.numberType === 4 && number.prefered === true);
          const phone = resp.fanNumbers.find((number) => number.numberType === 3 && number.prefered === true);

          this.fanNumbers = resp.fanNumbers;
          this.email = email ? email.number : '';
          this.phone = phone ? phone.number : '';

          this.fanview = resp;
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getPwdResetLink() {
      fanService.getPwdResetLink(this.ownerId, this.fanId)
        .then((resp) => this.resetPwdLink = resp);
    },
    deleteFanData() {
      this.$store.dispatch('set_isLoadingActive', true);

      fanService.deleteFanData(
        this.fanId,
        this.ownerId,
      )
        .then(() => {
          this.deleteSuccessAlert();

          this.$store.commit('RESET_HOLDLIST');

          this.goToContacts(false);
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    checkItemAccess(access) {
      if (!access) return true;

      return this.accessValues.includes(access);
    },
    copyText(text, message) {
      navigator.clipboard.writeText(text);

      this.$vs.notify({
        title: 'Copiado',
        text: message || '',
        color: 'primary',
      });

      this.resetPwdPopup = false;
    },
    addScrollableTabsAnimation() {
      const viewportHeight = window.innerHeight;

      // Add animation
      this.animations.forEach((animation) => {
        const { stickyElement, stickyStart, stickyEnd } = animation;

        document.addEventListener('scroll', () => {
          const scrollTop = window.scrollY + viewportHeight;

          if (scrollTop < stickyStart && scrollTop < stickyEnd) {
            if (stickyElement.style) {
              stickyElement.style.top = '0px';
              stickyElement.style.boxShadow = 'none';
            }
          }

          if (scrollTop > stickyStart && scrollTop < stickyEnd) {
            if (stickyElement.style) {
              const newOffset = Math.abs(stickyStart - scrollTop);
              stickyElement.style.top = `${newOffset}px`;
              stickyElement.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 3px 10px';
            }
          }
        });
      });
    },
    setupScrollableTabs(card) {
      const viewportHeight = window.innerHeight;

      // Get all scrollable tab cards
      const scrollableTabsCards = Array.from(document.querySelectorAll('.tabs-card--scrollable'));

      // Get sticky position top from first card
      const cardElement = scrollableTabsCards[0];
      const initialOffsetTop = cardElement.getBoundingClientRect().top;
      const stickyStart = viewportHeight + initialOffsetTop;

      // Get sticky position bottom from last card chapter
      const initialOffsetBottom = card.lastChapter.getBoundingClientRect().top;
      const stickyEnd = viewportHeight + initialOffsetBottom;

      // Add scrollable tabs card
      const found = this.animations.find((c) => c.id === card.id);

      if (!found) {
        this.animations.push({
          id: card.id,
          stickyElement: card.header,
          stickyStart,
          stickyEnd,
        });
      }

      if (this.animations.length === 2) {
        this.addScrollableTabsAnimation();
      }
    },
    /* Popups */
    confirmDeleteDialog() {
      this.deleteDialog = true;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Excluir Cadastro',
        text: `Tem certeza que quer excluir os dados de ${this.fanName}? `,
        acceptText: 'Confirmar',
        cancelText: 'Cancelar',
        accept: () => this.deleteFanData(),
        cancel: () => this.deleteDialog = false,
      });
    },
    /* Alerts */
    deleteSuccessAlert() {
      this.$vs.notify({
        color: 'success',
        title: 'FanID deletado com sucesso',
      });
    },
    /* Router */
    goToContacts(fromFilter) {
      if (this.returnLink.length === 0) {
        this.$router.push({
          name: 'Lista de Registros',
          params: { fromFilter },
        });
      } else {
        this.$router.push(this.returnLink);
      }
    },
  },
};
</script>

<style lang="scss">
main {
  overflow-y: auto;
}

.fanview-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.main {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 1.25rem;
  overflow: hidden;
  width: 100%;
}

.c-vs-input--copy {
  font-size: 0.875rem;
  width: 70%;

  .vs-con-input .vs-icon.vs-input--icon {
    top: 15px;
    left: 7px;
  }
}
</style>
