<template>
  <span :style="getStyle()" />
</template>

<script>
export default {
  name: 'Divider',
  props: {
    direction: {
      type: String,
      default: 'horizontal',
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  methods: {
    getStyle() {
      const size = this.theme === 'dark' ? 'calc(425px - 6.5rem)' : '100%';

      const backgroundColor = this.theme === 'light'
        ? 'rgba(0, 0, 0, 0.1)'
        : 'rgba(255, 255, 255, 0.1)';

      const width = this.direction === 'horizontal'
        ? size
        : '1px';

      const height = this.direction === 'horizontal'
        ? '1px'
        : size;

      return {
        backgroundColor,
        display: 'block',
        height,
        width
      };
    }
  }
}
</script>
