const sports = [
  {
    name: 'Academia',
    icon: 'fitness_center',
    library: 'mi'
  },
  {
    name: 'Atletismo',
    icon: 'sports',
    library: 'mi'
  },
  {
    name: 'Automobilismo',
    icon: 'sports_motorsports',
    library: 'mi'
  },
  {
    name: 'Badminton',
    icon: 'sports_tennis',
    library: 'mi'
  },
  {
    name: 'Basquete',
    icon: 'sports_basketball',
    library: 'mi'
  },
  {
    name: 'Basquete 3×3',
    icon: 'sports_basketball',
    library: 'mi'
  },
  {
    name: 'Beach Tennis',
    icon: 'sports_tennis',
    library: 'mi'
  },
  {
    name: 'Beisebol',
    icon: 'sports_baseball',
    library: 'mi'
  },
  {
    name: 'BMX',
    icon: 'pedal_bike',
    library: 'mi'
  },
  {
    name: 'Bobsled',
    icon: 'snowmobile',
    library: 'mi'
  },
  {
    name: 'Bocha',
    icon: 'tag',
    library: 'mi'
  },
  {
    name: 'Bodyboard',
    icon: 'surfing',
    library: 'mi'
  },
  {
    name: 'Boliche',
    icon: 'fa-regular fa-bowling-ball-pin',
    library: 'fa'
  },
  {
    name: 'Boxe',
    icon: 'sports_mma',
    library: 'mi'
  },
  {
    name: 'Calistenia',
    icon: 'sports_gymnastics',
    library: 'mi'
  },
  {
    name: 'Canoagem',
    icon: 'kayaking',
    library: 'mi'
  },
  {
    name: 'Capoeira',
    icon: 'sports_gymnastics',
    library: 'mi'
  },
  {
    name: 'Cheerleading',
    icon: 'emoji_people',
    library: 'mi'
  },
  {
    name: 'Ciclismo de Estrada',
    icon: 'directions_bike',
    library: 'mi'
  },
  {
    name: 'Ciclismo de Pista',
    icon: 'directions_bike',
    library: 'mi'
  },
  {
    name: 'Corrida de Aventura',
    icon: 'run_circle',
    library: 'mi'
  },
  {
    name: 'Corrida de Rua',
    icon: 'run_circle',
    library: 'mi'
  },
  {
    name: 'Cross Country',
    icon: 'run_circle',
    library: 'mi'
  },
  {
    name: 'Crossfit',
    icon: 'fitness_center',
    library: 'mi'
  },
  {
    name: 'Curling',
    icon: 'crisis_alert',
    library: 'mi'
  },
  {
    name: 'Dama',
    icon: 'fa-solid fa-game-board-simple',
    library: 'fa'
  },
  {
    name: 'Dança Esportiva',
    icon: 'emoji_people',
    library: 'mi'
  },
  {
    name: 'Escalada Esportiva',
    icon: 'hiking',
    library: 'mi'
  },
  {
    name: 'Esgrima',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'E-Sports',
    icon: 'sports_esports',
    library: 'mi'
  },
  {
    name: 'Fisiculturismo',
    icon: 'fitness_center',
    library: 'mi'
  },
  {
    name: 'Futebol',
    icon: 'sports_soccer',
    library: 'mi'
  },
  {
    name: 'Futebol Americano',
    icon: 'sports_football',
    library: 'mi'
  },
  {
    name: 'Futebol de Areia',
    icon: 'sports_soccer',
    library: 'mi'
  },
  {
    name: 'Futevôlei',
    icon: 'sports_soccer',
    library: 'mi'
  },
  {
    name: 'Futsal',
    icon: 'sports_soccer',
    library: 'mi'
  },
  {
    name: 'Ginástica Artística',
    icon: 'sports_gymnastics',
    library: 'mi'
  },
  {
    name: 'Ginástica Ritmica',
    icon: 'sports_gymnastics',
    library: 'mi'
  },
  {
    name: 'Goalball',
    icon: 'sports_soccer',
    library: 'mi'
  },
  {
    name: 'Golfe',
    icon: 'golf_course',
    library: 'mi'
  },
  {
    name: 'Handebol',
    icon: 'sports_handball',
    library: 'mi'
  },
  {
    name: 'Highline',
    icon: 'density_large',
    library: 'mi'
  },
  {
    name: 'Hipismo',
    icon: 'fa-regular fa-horse-saddle',
    library: 'fa'
  },
  {
    name: 'Hóquei',
    icon: 'sports_hockey',
    library: 'mi'
  },
  {
    name: 'Hóquei de Grama',
    icon: 'sports_hockey',
    library: 'mi'
  },
  {
    name: 'Jiu-Jitsu',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Jogo de Malha',
    icon: 'crisis_alert',
    library: 'mi'
  },
  {
    name: 'Judô',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Karatê',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Kickboxing',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Kitesurfe',
    icon: 'kitesurfing',
    library: 'mi'
  },
  {
    name: 'Kung Fu',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Levantamento de Peso',
    icon: 'fitness_center',
    library: 'mi'
  },
  {
    name: 'Luge',
    icon: 'sledding',
    library: 'mi'
  },
  {
    name: 'Maratona',
    icon: 'directions_run',
    library: 'mi'
  },
  {
    name: 'MMA',
    icon: 'sports_mma',
    library: 'mi'
  },
  {
    name: 'Motociclismo',
    icon: 'sports_motorsports',
    library: 'mi'
  },
  {
    name: 'Mountain Biking',
    icon: 'pedal_bike',
    library: 'mi'
  },
  {
    name: 'Muay Thai',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Nado Artístico',
    icon: 'pool',
    library: 'mi'
  },
  {
    name: 'Natação',
    icon: 'pool',
    library: 'mi'
  },
  {
    name: 'Parapente',
    icon: 'paragliding',
    library: 'mi'
  },
  {
    name: 'Patinação',
    icon: 'roller_skating',
    library: 'mi'
  },
  {
    name: 'Patinação Artística no Gelo',
    icon: 'ice_skating',
    library: 'mi'
  },
  {
    name: 'Patinação de Velocidade no Gelo',
    icon: 'ice_skating',
    library: 'mi'
  },
  {
    name: 'Polo Aquático',
    icon: 'pool',
    library: 'mi'
  },
  {
    name: 'Rafting',
    icon: 'kayaking',
    library: 'mi'
  },
  {
    name: 'Remo',
    icon: 'rowing',
    library: 'mi'
  },
  {
    name: 'Rugby',
    icon: 'sports_rugby',
    library: 'mi'
  },
  {
    name: 'Saltos Ornamentais',
    icon: 'settings_accessibility',
    library: 'mi'
  },
  {
    name: 'Skate',
    icon: 'skateboarding',
    library: 'mi'
  },
  {
    name: 'Skeleton',
    icon: 'sledding',
    library: 'mi'
  },
  {
    name: 'Ski Esportivo',
    icon: 'downhill_skiing',
    library: 'mi'
  },
  {
    name: 'Slackline',
    icon: 'density_large',
    library: 'mi'
  },
  {
    name: 'Snowboard Esportivo',
    icon: 'snowboarding',
    library: 'mi'
  },
  {
    name: 'Softball',
    icon: 'sports_baseball',
    library: 'mi'
  },
  {
    name: 'Squash',
    icon: 'sports_tennis',
    library: 'mi'
  },
  {
    name: 'Stand Up Paddle',
    icon: 'rowing',
    library: 'mi'
  },
  {
    name: 'Surfe',
    icon: 'surfing',
    library: 'mi'
  },
  {
    name: 'Taekwondo',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Tiro com Arco',
    icon: 'fa-solid fa-bullseye-arrow',
    library: 'fa'
  },
  {
    name: 'Tiro Esportivo',
    icon: 'fa-solid fa-person-rifle',
    library: 'fa'
  },
  {
    name: 'Triathlon',
    icon: 'directions_run',
    library: 'mi'
  },
  {
    name: 'Tênis',
    icon: 'sports_tennis',
    library: 'mi'
  },
  {
    name: 'Tênis de Mesa',
    icon: 'sports_tennis',
    library: 'mi'
  },
  {
    name: 'Vela',
    icon: 'sailing',
    library: 'mi'
  },
  {
    name: 'Voo Livre',
    icon: 'paragliding',
    library: 'mi'
  },
  {
    name: 'Voleibol',
    icon: 'sports_volleyball',
    library: 'mi'
  },
  {
    name: 'Voleibol de Praia',
    icon: 'sports_volleyball',
    library: 'mi'
  },
  {
    name: 'Windsurf',
    icon: 'kitesurfing',
    library: 'mi'
  },
  {
    name: 'Wingsuit',
    icon: 'paragliding',
    library: 'mi'
  },
  {
    name: 'Wrestling',
    icon: 'sports_kabaddi',
    library: 'mi'
  },
  {
    name: 'Xadrez',
    icon: 'fa-solid fa-chess-knight-piece',
    library: 'fa'
  },
  {
    name: 'Nenhum',
    icon: 'block',
    library: 'mi'
  },
  {
    name: 'Outro',
    icon: 'more_horiz',
    library: 'mi'
  }
];

const getSportIcon = (sportName) => {
  const fallback = sports[sports.length - 1]

  return sports.find((sport) => sport.name === sportName) || fallback;
}

export default getSportIcon;
