/**
 * Determine whether to use a dark or light foreground color based on a background color.
 *
 * @param background The background HEX color.
 * @return {string} "dark" or "light" contrast.
 */
const getTextContrast = (background) => {
  const hex = background.replace('#', '');

  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return (yiq >= 128) ? 'dark' : 'light';
};

export default getTextContrast;
